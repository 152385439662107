
// http://44.195.135.3:4000/
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.12.8:5043/api/web/";
// const IMG_URL = "http://192.168.6.74:5000/api/uploads/images/";
// const LEAD_URL = "http://192.168.6.74:5000/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.6.74:5000/";

//DEMO  
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://162.19.146.134:1208/api/web/";
// const IMG_URL = "http://162.19.146.134:1208/api/uploads/images/";
// const LEAD_URL = "http://162.19.146.134:1208/api/web/";
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// // const DEFAULT_KEY ='903'
// const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
// const BASE = "http://192.168.6.74:5000/";

//LIVE
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://admin.mlm.kuechen.club/api/web/";
const IMG_URL = "https://admin.mlm.kuechen.club/api/uploads/images/";
const LEAD_URL = "https://admin.mlm.kuechen.club/api/web/";
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='903'
const DEFAULT_KEY='387245a5a919ea2071cc406b10b89d4685e5cc8e'
const BASE = "https://admin.mlm.kuechen.club/";




export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
    DEFAULT_KEY,
    LEAD_URL
}


