import { BASE_URL } from "../../configuration/apiconfig";
import UserStore from "../../store/UserStore";
import axios from "axios";
import API from "../../api/api";

export default class SalesService {
  key = localStorage.getItem("apiKey");


  salesOrder = async () => {
    return await API.API.get("home/salesorder")
      .then((res) => {
        if(res.status===200){
          return  res.data
       }
       else{
         return res;
       }
      })
      .catch((error) => console.log(error));
  };



 
}
