import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SalesService from '../../../service/sales';
import { AlertMessage, PageTitle } from '../../components/common';
import DataTable from "react-data-table-component";
import moment from 'moment';




function Sales() {
    const { t } = useTranslation();

    const [state, setState] = useState({
        notify: {
            show: false,
            type: "",
            message: "",
            header: "",
        },
        data: {}
    });

    const service = new SalesService()

    useEffect(() => {
        service.salesOrder().then(res => {
            if (res.status) {
                setState((prev) => ({
                    ...prev,
                    data: res.productSales
                }))
            }
        })
    }, [])

    const columns = [
        {
            name: 'Product',
            selector: row => row.product_name,
        },
        {
            name: 'Quantity',
            selector: row => row.sales_quantity,
        },
        {
            name: 'Commission',
            selector: row => row.product_sale_commission,
        },
        {
            name: 'Sale Date',
            selector: row => moment(row.sale_date).format("DD-MM-YYYY") ?? "Not available",
        },
    ];

    // const data = [
    //     {
    //         id: 1,
    //         title: 'Beetlejuice',
    //         year: '1988',
    //     },
    //     {
    //         id: 2,
    //         title: 'Ghostbusters',
    //         year: '1984',
    //     },
    // ]

    const dissmissNotify = () => {
        setState((prev) => ({
            ...prev,
            notify: {
                ...prev.notify,
                show: false,
            },
        }));
    };


    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <PageTitle title="sales" />
            </div>

            <div style={{ margin: '20px', boxShadow: '2px 2px 10px #ccc' }}>

                <DataTable
                    columns={columns}
                    data={state.data}
                    highlightOnHover
                    // progressPending={ContentData.pending}
                    progressComponent={<div>Loading</div>}
                    persistTableHead
                    noHeader
                    pagination
                    paginationServer
                    responsive
                // paginationTotalRows={ContentData.totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                // noDataComponent={t("Common.noDataRecordsToDisplay")}
                //onSort={handleOnSort}
                />
            </div>



            {state.notify.show && (
                <AlertMessage
                    show={state.notify.show}
                    message={state.notify.message}
                    dismiss={dissmissNotify}
                    type={state.notify.type}
                    header={state.notify.header}
                />
            )}
        </div>
    );
}

export default Sales