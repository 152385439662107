import React, { useState } from 'react';
import style from './dashboard.module.scss';
import { Popover, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AlertMessage } from '../common';
function Links({ iscopy, icon, link }) {
    const { t } = useTranslation();

    const [showDialog, setShowDialog] = useState(true);
    const [urlState, setUrlstate] = useState();

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    //   const handleShow = () => setShowModal(true);

    function shareButton(url) {
        // console.log("url:", icon)
        setShowModal(true)
        setUrlstate(url)
        // const iconName = icon == "fa fa-facebook" ? "facebook" : icon == "fa fa-twitter" ? "twitter" : "linkedin"

        //     if (window.confirm(`Do you want to redirect to ${iconName}?`)) {
        //         window.open(url, '_blank', 'height=450,width=550');
        //       }

    }

    const handleConfirmation = () => {
        window.open(urlState, '_blank', 'height=450,width=550');
        handleClose();
    };


    const popover = (
        <Popover id="popover-basic" varient="success">
            <Popover.Title as="h3" >{t('Dashboard.linkCopied')}</Popover.Title>
        </Popover>
    );
    function Clipboard_CopyTo(value) {
        var tempInput = document.createElement("input");
        tempInput.value = value;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    }

    const iconName = icon == "fa fa-facebook" ? "facebook" : icon == "fa fa-twitter" ? "twitter" : "linkedin"

    return (
        <>
            {
                iscopy ?
                    <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                        <a onClick={() => Clipboard_CopyTo(link)} ><div className={style.profilesoc_share_bx_ico}><i className={icon}></i></div></a>
                        {/* <Button onClick={()=>Clipboard_CopyTo(link)} className={style.profilesoc_share_bx_ico}><i className={icon}></i></Button> */}
                    </OverlayTrigger>
                    :
                    <a onClick={() => shareButton(link)} className={style.profilesoc_share_bx_ico} >
                        {icon=='fa fa-twitter' ?
                    <svg style={{width:'16px', marginBottom:'6px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                    :
                    <i className={icon}></i>
                    }
                    </a>
            }
            <Modal show={showModal} onHide={handleClose} >
                <Modal.Header closeButton style={{background:'#fff'}}>
                    <Modal.Title>Redirect Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{background:'#fff'}}>
                    Do you want to redirect to {iconName}?
                </Modal.Body>
                <Modal.Footer style={{background:'#fff'}}>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmation}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Links
